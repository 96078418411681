<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div
      @mousedown.stop
      class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Find a program</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <LoadingMessage v-if="isLoading"></LoadingMessage>

          <ErrorMessage v-if="error" :error="error"></ErrorMessage>

          <div v-if="!isLoading && programs.length">
            <div class="list-group list-group-flush">
              <div
                v-for="(program, programIndex) in programs"
                :key="`key-${programIndex}`"
                class="list-group-item d-flex align-items-center"
              >
                <div class="form-check m-0">
                  <input
                    @change="selectedProgramId = program.programId"
                    :id="`p-${programIndex}`"
                    name="p"
                    class="form-check-input"
                    type="radio"
                  >
                  <label :for="`p-${programIndex}`" class="form-check-label">
                    {{ program.name }}
                  </label>
                </div>
              </div>
            </div>

            <div class="d-flex border-top pt-3">
              <div class="flex-grow-1 me-3">
                <div class="alert alert-primary mb-0">
                  <div><strong>Start date:</strong> {{ startDate }}</div>
                </div>
              </div>
              <div>
                <SubmitButton
                  @click="assignProgram"
                  :text="'Assign program'"
                  :textBusy="'Assigning...'"
                  :isLoading="isAssigning"
                  :disabled="isAssigning || !selectedProgramId"
                  class="btn btn-primary h-100"
                ></SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpGet } from '@/core/http';
import dayjs from 'dayjs';

export default {
  name: 'ClientFindProgramModal',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    startDate() {
      const d = dayjs(this.date);
      return this.$options.filters.dateReadableLong(d);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async fetchPrograms() {
      try {
        this.error = null;
        this.isLoading = true;
        const res = await httpGet('/program');
        this.programs = res.data;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    async assignProgram() {
      try {
        this.error = null;
        this.isAssigning = true;
        this.$store.dispatch('calendar/setBusy', true);
        const programId = this.selectedProgramId;
        const { userId, date } = this;
        await this.$store.dispatch('calendar/assignProgram', {
          programId,
          userId,
          date,
        });
        this.close();
      } catch (err) {
        this.error = err;
      } finally {
        this.isAssigning = false;
        this.$store.dispatch('calendar/setBusy', false);
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isAssigning: false,
      error: null,
      programs: [],
      selectedProgramId: null,
    };
  },
  mounted() {
    this.fetchPrograms();
  },
};
</script>
